import request from "@/utils/request";

// 人员详情
export function persongetDetailById(arg: any) {
  return request({
    url: "/project/person/getDetailById",
    method: "get",
    params: arg,
  });
}

export function listProjectCompany(args: any) {
  return request({
    url: "/project/projectDept/listProjectCompany",
    method: "get",
    params: args,
  });
}

export function listProjectStation(data: any) {
  return request({
    url: "/project/projectStation/listProjectStation/" + data,
    method: "get",
  });
}

export function projectTeamlistProjectTeam(id: any) {
  return request({
    url: "/project/projectTeam/listProjectTeam/" + id,
    method: "get",
  });
}
// 进度详情
export function listQualification(arg: any) {
  return request({
    url: "/project/person/listQualification",
    method: "get",
    params: arg,
  });
}
//查询个人的奖惩列表
export function listByUserr(data: any) {
  return request({
    url: "/project/projectBehavior/listByUser",
    method: "get",
    params: data,
  });
}
export function learnRecordByUser(data: any) {
  return request({
    url: "/train/class/learnRecordByUser",
    method: "get",
    params: data,
  });
}
