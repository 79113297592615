
	import {
		Options,
		Vue
	} from "vue-property-decorator";
	import * as api from "@/api/personinfo";

	@Options({
		components: {},
	})
	export default class extends Vue {
		projectId: any = "";
		userId: any = "";
		bOverlayShow = false;
		oBaseInfo: any = {};
		aCompanyList = [];
		aStationList = [];
		aProjectTeamlist = [];
		one = ["1"];
		two = ["1"];
		three = ["1"];
		four = ["1"];
		award = ["1"];
		punishment = ["1"];
		aQualificationList: any = [];
		aGoodHavior: any = [];
		aNoGoodHavior: any = [];
		aEducationInfo: any = {};
		img = require("@/assets/zizhizhengshu.png");
		created() {
			this.projectId = this.$route.query.projectId;
			this.userId = this.$route.query.userId;
			this.fGetBaseInfo();
			this.fGetListQualification();
			this.fGetListByUserr();
			this.fGetLearnRecordByUser();
		}
		async fGetBaseInfo() {
			let res: any = await api.persongetDetailById({
				projectId: this.projectId,
				userId: this.userId,
			});
			if (res.code !== 0) {
				return this.$notify({
					type: "warning",
					message: res.msg,
				});
			}
			this.oBaseInfo = res.data;
		}
		async fGetListQualification() {
			let res: any = await api.listQualification({
				projectId: this.projectId,
				userId: this.userId,
			});
			if (res.code !== 0) {
				return this.$notify({
					type: "warning",
					message: res.msg,
				});
			}
			this.aQualificationList = res.data;
		}
		async fGetListProjectCompany() {
			let res: any = await api.listProjectCompany({
				projectId: this.projectId,
			});
			if (res.code !== 0) {
				return this.$notify({
					type: "warning",
					message: res.msg,
				});
			}
			this.aCompanyList = res.data;
		}
		async fListProjectStation() {
			let res: any = await api.listProjectStation({
				projectId: this.projectId,
			});
			if (res.code !== 0) {
				return this.$notify({
					type: "warning",
					message: res.msg,
				});
			}
			this.aStationList = res.data;
		}
		async fGetProjectTeamlistProjectTeam() {
			let res: any = await api.projectTeamlistProjectTeam({
				projectId: this.projectId,
			});
			if (res.code !== 0) {
				return this.$notify({
					type: "warning",
					message: res.msg,
				});
			}
			this.aProjectTeamlist = res.data;
		}
		async fGetListByUserr() {
			//良好行为
			let res: any = await api.listByUserr({
				userId: this.userId,
				projectId: this.projectId,
				type: 0,
			});
			if (res.code !== 0) {
				this.$toast.fail(res.msg);
			} else {
				this.aGoodHavior = res.data;
			}
			//不良行为
			let reson: any = await api.listByUserr({
				userId: this.userId,
				projectId: this.projectId,
				type: 1,
			});
			if (reson.code !== 0) {
				this.$toast.fail(reson.msg);
			} else {
				this.aNoGoodHavior = reson.data;
			}
		}
		async fGetLearnRecordByUser() {
			let res: any = await api.learnRecordByUser({
				userId: this.userId,
				projectId: this.projectId,
			});
			if (res.code !== 0) {
				return this.$toast.fail(res.msg);
			}
			this.aEducationInfo = {};
			this.aEducationInfo = res.data;
		}
	}
